<template>
    <div class="container">
        <div class="body">
            <div class="f-1">
                <div class="title">码库</div>
                <div class="search">
                    <div class="search-item">
                        <div class="key">兑换码状态:</div>
                        <div class="value">
                            <el-select class="width" v-model="searchForm.status" placeholder="请选择状态">
                                <el-option v-for="item in statusList" :key="item.value" :label="item.name"
                                    :value="item.value"></el-option>
                            </el-select>
                        </div>
                    </div>
                    <!-- <div class="search-item">
                        <div class="key">兑换码名称:</div>
                        <div class="value">
                            <el-input v-model="searchForm.name" placeholder="请输入名字"></el-input>
                        </div>
                    </div> -->
                    <el-button class="btn" @click="handleReset">重置</el-button>
                    <el-button type="primary" class="btn" @click="pageChange(1)">查询</el-button>
                </div>
            </div>
            <div class="f-2">
                <div class="name">{{detail.name}}</div>
                <div class="time">有效期：{{detail.begin_time | timeFilter(5)}}-{{detail.end_time | timeFilter(5)}}</div>
            </div>
            <div class="f-3">
                <el-table :data="tableData" tooltip-effect="dark" style="width: 100%" size="medium"
                    :header-cell-style="{height: '40px', background: 'linear-gradient(180deg,rgba(251,252,253,1) 0%,rgba(246,248,250,1) 100%)'}"
                    :cell-style="{height: '50px',padding: '5px 0'}" header-align="center">
                    <el-table-column prop="cdkey" label="兑换号码" :show-overflow-tooltip="true">
                    </el-table-column>
                    <el-table-column prop="status" label="状态" :show-overflow-tooltip="true">
                        <template slot-scope="scope">
                            {{scope.row.status | convertStatusFilter}}
                        </template>
                    </el-table-column>
                    <el-table-column prop="user_name" label="兑换人" :show-overflow-tooltip="true">
                        <template slot-scope="scope">
                            {{scope.row.user_name || '-'}}
                        </template>
                    </el-table-column>
                    <el-table-column prop="create_time" label="创建时间" :show-overflow-tooltip="true">
                        <template slot-scope="scope">
                            {{scope.row.create_time | timeFilter}}
                        </template>
                    </el-table-column>
                    <el-table-column prop="exchange_time" label="兑换时间" :show-overflow-tooltip="true">
                        <template slot-scope="scope">
                            {{scope.row.exchange_time | timeFilter}}
                        </template>
                    </el-table-column>
                    <div class="" slot="empty">
                        <no-data></no-data>
                    </div>

                </el-table>
                <div class="page-container">
                    <el-pagination layout="total, sizes, prev, pager, next" background @size-change="pageSizeChange"
                        @current-change="pageChange" :current-page="currPage" :total="total" :page-size="pageSize">
                    </el-pagination>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Core from '@/core';
export default {
    components: {
        NoData: () => import('@/components/Empty.vue')
    },
    props: {},
    data() {
        return {
            id: '',
            statusList: Core.Const.COURSE.CONVERT_STATUS,
            searchForm: {
                status: '',
                name: ''
            },
            tableData: [],
            total: 0,
            currPage: 1,
            pageSize: 10,
            detail: {}
        };
    },
    watch: {},
    created() {
        this.id = this.$route.query.id;
    },
    computed: {},
    mounted() {
        if (this.id) {
            this.getConbertDetail();
            this.getTableData();
        }
    },
    methods: {
        getConbertDetail() {
            Core.Api.ExChangeCode.detail(this.id).then((res) => {
                this.detail = res.detail;
            });
        },
        pageChange(page) {
            this.currPage = page;
            this.getTableData();
        },
        pageSizeChange(size) {
            this.pageSize = size;
            this.getTableData();
        },
        getTableData() {
            Core.Api.ExChangeCode.listByActivity(this.id, this.searchForm.status, this.searchForm.name,this.currPage, this.pageSize).then((res) => {
                this.total = res.count;
                this.tableData = res.list;
            });
        },
        handleReset() {
            this.searchForm.status = '';
            this.searchForm.name = '';
            this.getTableData();
        }
    }
};
</script>
<style lang="scss" scoped>
.container {
    .body {
        padding: 20px;
        margin: 20px;
        background: white;

        .f-1 {
            display: flex;
            justify-content: space-between;
            align-items: center;

            .title {
                font-size: 16px;
                font-weight: 500;
            }

            .search {
                display: flex;
                flex-direction: row;
                align-items: center;
                .search-item {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    .key {
                        margin-right: 10px;
                        font-size: 14px;
                    }
                    .value {
                        width: 268px;
                        margin-right: 20px;
                        .width {
                            width: 268px;
                        }
                    }
                }
                .btn {
                    width: 65px;
                }
            }
        }
        .f-2 {
            display: flex;
            flex-direction: column;
            justify-content: center;
            height: 90px;
            margin-top: 15px;
            background: #f7f9fa;
            padding-left: 12px;
            .name {
                font-size: 16px;
                color: #323538;
                font-weight: 500;
                margin-bottom: 8px;
            }
            .time {
                color: #999999;
                font-size: 14px;
            }
        }
        .f-3 {
            margin-top: 20px;

            .page-container {
                margin-top: 15px;
                display: flex;
                justify-content: flex-end;
            }
        }
    }
}
</style>